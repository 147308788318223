<template lang="pug">
  transition(:name="mobileNavSlideAnimation" mode="out-in")
    .site-nav-mobile
      .site-nav-mobile-header.xs-12
        .top-wrap.xs-12
          .left-wrap
            button.hamburger(type="button" @click="toggleMobileSiteNav" :class="{ open: siteNavMobileNavOpen }" data-mr-ass="hamburger-menu" :aria-label="siteNavMobileNavOpen ? 'Close Menu': 'Open Menu'")
              .stripe-top
              .stripe-bottom

          .middle-wrap
            a.mr-logo(href="/" target="_self")
              span.ada-tooltip home

          .right-wrap
            button.search-trigger.btn-reset.color-black.xs-f-medium.md-f-xmedium.lg-hide.max-at-tweak(@click="toggleSearch")
              span.icon-magnify
                span.ada-tooltip open search

            a.cart-link(href="/cart" target="_self" aria-label="Cart")
              .icon-bag.color-black
              span.ada-tooltip go to cart
              .item-count
                span {{cic}}

      transition(:name="mobileNavSlideAnimation" mode="out-in")
        component(:is="currentMobileMenuComponent")

</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import SiteNavTrackMixin from "@components/SiteNav/SiteNavTrackMixin/SiteNavTrackMixin";

  export default {
    name: "SiteNavMobile",

    components: {
      'SiteNavMobileMainMenu': () => import('./SiteNavMobileMainMenu'),
      'SiteNavMobileSubMenu': () => import('./SiteNavMobileSubMenu'),
      'SiteNavMobileShopMenu': () => import('./SiteNavMobileShopMenu')
    },

    mixins: [SiteNavTrackMixin],

    inject: ['getObjProperty'],

    computed: {
      ...mapState('cart', ['cic']),
      ...mapState('siteNav', [
        'searchOpen'
      ]),

      ...mapState('siteNav', [
        'mobileNavSlideAnimation',
        'currentMobileNav',
        'siteNavMobileNavOpen'
      ]),

      ...mapGetters('siteNav', [
        'currentMobileNavData',
      ]),

      currentMobileMenuComponent() {
        return this.getObjProperty(this.currentMobileNavData, 'mobileComponent');
      }
    },

    watch: {
      currentMobileNav(val) {
        //- only track when nav is open
        if (this.siteNavMobileNavOpen) {
          this.mix_trackNav(val);
        }
      },

      siteNavMobileNavOpen(val) {
        if (!val) {
          document.body.classList.remove("site-nav-mobile-lock");
        } else {
          //- position fixed can cause some visual issues, so we delay it until nav is open
          document.body.classList.add("site-nav-mobile-lock");
        }

        //- since TOP_NAV is always selected as default we need to track it on mobile menu open
        if (val) {
          this.mix_trackNav(this.currentMobileNav);
        }
      }
    },

    mounted() {
      this.setCurrentMobileNav('TOP_NAV');

      document.body.addEventListener('keydown', e => {
        if (e.keyCode === 27) {
          this.setSiteNavMobileNavOpen(false);
        }
      });
    },

    methods: {
      ...mapActions('siteNav', [
        'toggleSearch'
      ]),

      ...mapActions('siteNav', [
        'toggleMobileSiteNav'
      ]),

      ...mapMutations('siteNav', [
        'setCurrentMobileNav',
        'setSiteNavMobileNavOpen'
      ])
    },
  };
</script>

<style scoped lang="stylus">
  .site-nav-mobile
    height 100%

  .site-nav-mobile-header
    border-bottom 1px solid ui-color-3

</style>